"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const antd_1 = require("antd");
const TextSearch = ({ allowClear, placeholder, disabled = false, maxLength = 500, value, setValue, }) => {
    const [searchText, setSearchText] = (0, react_1.useState)('');
    return ((0, jsx_runtime_1.jsx)(antd_1.Input, { allowClear: allowClear ? { clearIcon: allowClear } : false, placeholder: placeholder, disabled: disabled, maxLength: maxLength, value: searchText || value, onChange: (e) => {
            setSearchText(e.target.value);
            setValue(e.target.value);
        } }));
};
exports.default = TextSearch;
