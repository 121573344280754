"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// removes the style attribute from the html string being provided by copying the content
exports.default = (value = '') => {
    // sanitizing the value as when user enters anything and removes it, an empty <p> tag is left behind
    const richText = value.replaceAll('<p><br></p>', '');
    const htmlNode = document.createElement('div');
    htmlNode.innerHTML = richText;
    htmlNode.querySelectorAll('*').forEach((node) => node.removeAttribute('style'));
    return htmlNode.innerHTML;
};
