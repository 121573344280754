"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMutateWrapper = void 0;
const antd_1 = require("antd");
const react_query_1 = require("@tanstack/react-query");
const react_router_dom_1 = require("react-router-dom");
const lodash_1 = require("lodash");
const ApplicationContext_1 = require("../context/ApplicationContext");
const singularize = (str) => str.slice(0, -1);
/**
 * Custom wrapper for @tanstack/react-query's useMutate Function to work with antd forms.
 * Provides messages when a mutate action is success or failure.
 *
 * @param key - Unique key for mutation
 * @param mutateFn - Async function that executes mutation
 * @param options - Optional set of properties to override messages and provide end of action navigation
 * @returns MutateWrapperHookReturn
 */
const useMutateWrapper = (key, mutateFn, options) => {
    const qc = (0, react_query_1.useQueryClient)();
    const { user: { userId } } = (0, ApplicationContext_1.useApplicationContext)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const { navigateTo = null, successMessage = null, errorMessage = null, loadingMessage = null, onErrorCb = undefined, onSuccessCb = undefined, } = options;
    // messages
    const successMsg = successMessage !== null && successMessage !== void 0 ? successMessage : `${(0, lodash_1.startCase)(singularize(key))} Saved`;
    const errMsg = errorMessage !== null && errorMessage !== void 0 ? errorMessage : `Error Saving ${(0, lodash_1.startCase)(singularize(key))}`;
    const loadMsg = loadingMessage !== null && loadingMessage !== void 0 ? loadingMessage : `Saving ${(0, lodash_1.startCase)(singularize(key))}...`;
    const onSuccess = (data) => {
        // navigate only if url is supplied.
        if (navigateTo) {
            qc.invalidateQueries([key]);
            navigate(navigateTo);
        }
        onSuccessCb ? onSuccessCb(data) : antd_1.message.success({ content: successMsg, key }, 3);
    };
    const onError = (error) => {
        onErrorCb ? onErrorCb(error) : antd_1.message.error({ content: errMsg, key }, 3);
    };
    const onMutate = () => antd_1.message.loading({ content: loadMsg, key });
    const mutation = (0, react_query_1.useMutation)(mutateFn, { onError, onSuccess, onMutate });
    /**
     * Form submission handler (for use with antd forms). antd form supplies the values from form
     * As a curried function this allows to pass an optional set of values which will override values
     * of same keys supplied by antd Form.
     *
     * @param optionalValues - Optional set of values to supply additional values or to override values coming from antd
     */
    const onFormSubmit = (optionalValues) => (values) => {
        mutation.mutate(Object.assign(Object.assign(Object.assign({}, values), { createdBy: userId, modifiedBy: userId }), optionalValues));
    };
    const { isSuccess, isError, isLoading, isPaused } = mutation;
    return { onFormSubmit, isSuccess, isError, isLoading, isPaused };
};
exports.useMutateWrapper = useMutateWrapper;
