"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const antd_1 = require("antd");
const { Title, Link } = antd_1.Typography;
const { Item } = antd_1.Descriptions;
const labelStyle = {
    fontWeight: 700,
    color: 'var(--gray-20)',
};
const ConfigMeta = ({ configMetaData, activeLocale, activeLocales }) => {
    var _a;
    const activeLocaleName = (_a = activeLocales.find(({ code }) => code === activeLocale)) === null || _a === void 0 ? void 0 : _a.localeName;
    const renderData = (data) => {
        if (typeof data === 'boolean') {
            return data ? 'True' : 'False';
        }
        if (data === '' || data === null) {
            return 'Not Set';
        }
        if (typeof data === 'object') {
            if (Object.keys(data).includes('url')) {
                return (0, jsx_runtime_1.jsx)(Link, Object.assign({ href: data.url, target: '_blank', rel: 'noreferrer' }, { children: data.url }), data.url);
            }
            return activeLocales
                .filter(({ code }) => code === activeLocale)
                .map(({ code }) => renderData(data[code]));
        }
        return data;
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(antd_1.Divider, {}), (0, jsx_runtime_1.jsxs)(Title, Object.assign({ level: 5, style: { color: 'var(--color-primary)' } }, { children: ["Customized Properties", (0, jsx_runtime_1.jsx)(antd_1.Tag, Object.assign({ color: 'green', style: { marginLeft: '0.5rem' } }, { children: activeLocaleName }))] })), (0, jsx_runtime_1.jsx)(antd_1.Descriptions, Object.assign({ bordered: true, column: 1, style: { width: '50rem' }, size: 'small', labelStyle: labelStyle }, { children: Object.keys(configMetaData).sort((a, b) => a.localeCompare(b)).map((prop) => ((0, jsx_runtime_1.jsx)(Item, Object.assign({ label: prop }, { children: renderData(configMetaData[prop]) }), prop))) }))] }));
};
exports.default = ConfigMeta;
