"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const antd_1 = require("antd");
const lodash_1 = require("lodash");
const { Item } = antd_1.Descriptions;
const labelStyle = {
    fontWeight: 700,
    color: 'var(--gray-20)',
    backgroundColor: 'var(--gray-95)',
};
const ListProperties = ({ modelProperties }) => {
    var _a;
    const modelPropertyData = (_a = modelProperties === null || modelProperties === void 0 ? void 0 : modelProperties.sort((a, b) => a.propertyName.localeCompare(b.propertyName))) === null || _a === void 0 ? void 0 : _a.map(({ propertyId, propertyName, propertyType, isLocalizable }) => ((0, jsx_runtime_1.jsxs)(Item, Object.assign({ label: `Name: ${propertyName}` }, { children: [(0, jsx_runtime_1.jsx)(antd_1.Tag, Object.assign({ color: 'geekblue' }, { children: (0, lodash_1.upperCase)(propertyType) })), isLocalizable && (0, jsx_runtime_1.jsx)(antd_1.Tag, Object.assign({ color: 'purple' }, { children: "Localizable" }))] }), propertyId)));
    return ((0, jsx_runtime_1.jsx)(antd_1.Descriptions, Object.assign({ column: 1, bordered: true, style: { width: '40rem' }, size: 'small', labelStyle: labelStyle }, { children: modelPropertyData })));
};
exports.default = ListProperties;
