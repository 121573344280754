"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadFile = exports.generatePresignedUrl = exports.assetBucketUrl = exports.nanoid = void 0;
const nanoid_1 = require("nanoid");
const fileUploads_1 = require("../services/fileUploads");
exports.nanoid = (0, nanoid_1.customAlphabet)('abcdefghijklmnopqrttuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 32);
exports.assetBucketUrl = process.env.ZENITH_ENV === 'prod'
    ? 'https://cdn.zenith.lextegrity.com'
    : `https://cdn.${process.env.ZENITH_ENV}.zenith.lextegrity.com`;
const generatePresignedUrl = (tenantId, objectKey) => __awaiter(void 0, void 0, void 0, function* () {
    const { data: { data: preSignedUrl } } = yield (0, fileUploads_1.getPresignedUrl)(tenantId, objectKey);
    return preSignedUrl;
});
exports.generatePresignedUrl = generatePresignedUrl;
const uploadFile = (preSignedUrl, singleFile) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield fetch(preSignedUrl, {
            method: 'PUT',
            body: singleFile,
        });
        return response.ok;
    }
    catch (error) {
        console.error(error);
        return false;
    }
});
exports.uploadFile = uploadFile;
