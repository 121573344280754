"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const antd_1 = require("antd");
const react_router_dom_1 = require("react-router-dom");
const ApplicationContext_1 = require("../../../context/ApplicationContext");
const ServerError = ({ title = '500', subTitle = 'Sorry, something went wrong.', backBtnText = 'Back Home', }) => {
    const { setCurrentTab } = (0, ApplicationContext_1.useApplicationContext)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const handleBackClick = () => {
        setCurrentTab('');
        navigate('/');
    };
    return ((0, jsx_runtime_1.jsx)(antd_1.Result, { status: '500', title: title, subTitle: subTitle, extra: (0, jsx_runtime_1.jsx)(antd_1.Button, Object.assign({ type: 'primary', onClick: handleBackClick }, { children: backBtnText })) }));
};
exports.default = ServerError;
