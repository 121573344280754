"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const antd_1 = require("antd");
const react_router_dom_1 = require("react-router-dom");
const ApplicationContext_1 = require("../../context/ApplicationContext");
const TopMenu = () => {
    const { user: { isAdmin }, currentTab, setCurrentTab, signOut } = (0, ApplicationContext_1.useApplicationContext)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const defaultStyle = {
        backgroundColor: 'var(--color-primary-dark)',
        height: '100%',
        color: '#ffffffa6',
        fontWeight: 700,
    };
    const selectedStyle = {
        backgroundColor: 'var(--color-primary-darker)',
        height: '100%',
        color: 'var(--white)',
        borderColor: 'var(--color-primary-darker)',
    };
    const getStyle = (tab) => (currentTab === tab ? selectedStyle : defaultStyle);
    const primaryItems = [{
            key: 'configurations',
            label: 'Configurations',
            style: getStyle('configurations'),
            disabled: false,
        },
        {
            key: 'tenants',
            label: 'Tenants',
            style: getStyle('tenants'),
            disabled: false,
        },
        {
            key: 'emailTemplates',
            label: 'Email Templates',
            style: getStyle('emailTemplates'),
            disabled: false,
        },
        {
            key: 'applications',
            label: 'Applications',
            style: getStyle('applications'),
            disabled: !isAdmin,
        }, {
            key: 'models',
            label: 'Models',
            style: getStyle('models'),
            disabled: !isAdmin,
        }];
    const secondaryItems = [{
            key: 'account',
            label: 'Account',
            popupOffset: [0, 0],
            popupClassName: 'account-menu',
            children: [{
                    key: 'signOut',
                    label: 'Sign Out',
                    style: getStyle('signOut'),
                }],
        }];
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: 'navbar-menu' }, { children: [(0, jsx_runtime_1.jsx)(antd_1.Menu, { theme: 'dark', mode: 'horizontal', selectedKeys: [currentTab], style: Object.assign(Object.assign({}, defaultStyle), { flexGrow: 1 }), items: primaryItems.filter((item) => !item.disabled), onClick: ({ key }) => {
                    setCurrentTab(key);
                    navigate(`/${key}`);
                } }), (0, jsx_runtime_1.jsx)(antd_1.Menu, { theme: 'dark', mode: 'horizontal', style: defaultStyle, items: secondaryItems, onClick: ({ key }) => {
                    if (key === 'signOut') {
                        signOut();
                    }
                } })] })));
};
exports.default = TopMenu;
