"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    TENANTS: '/v1/tenants',
    APPLICATIONS: '/v1/apps',
    CONFIGURATIONS: '/v1/config',
    CONFIG_ITEMS: '/v1/config-items',
    MODELS: '/v1/models',
    MODEL_PROPERTIES: '/v1/model-properties',
    STATIC_MODELS: '/v1/s3/signed-url',
    COMPONENTS: '/v1/components',
    USERS: '/v1/users',
    EMAIL_TEMPLATES: '/v1/email-templates',
    LOCALES: '/v1/locales',
};
