"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
exports.default = (key, initialValue) => {
    const [data, setData] = (0, react_1.useState)(() => {
        try {
            const storedData = window.localStorage.getItem(key);
            return storedData ? JSON.parse(storedData) : initialValue;
        }
        catch (err) {
            console.error(err);
            return initialValue;
        }
    });
    const storeData = (value) => {
        try {
            setData(value);
            window.localStorage.setItem(key, JSON.stringify(value));
        }
        catch (err) {
            console.error(err);
        }
    };
    const clearData = () => {
        try {
            setData({});
            window.localStorage.removeItem(key);
        }
        catch (err) {
            console.error(err);
        }
    };
    return [data, storeData, clearData];
};
