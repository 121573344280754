"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = () => {
    try {
        const token = window.localStorage.getItem('AuthToken');
        return token === null || token === void 0 ? void 0 : token.replaceAll('"', '');
    }
    catch (err) {
        console.error(err);
        return null;
    }
};
