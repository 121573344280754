"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    CONFIGURATIONS: {
        ALL: 'configurations',
        CONFIG_DETAILS: 'configDetails',
        CHILD_ITEMS: 'childItems',
        TENANTS_FILTER: 'tenantsInConfigs',
        APPS_FILTER: 'appsInConfigs',
    },
    TENANTS: {
        ALL: 'tenants',
        LOCALES: 'locales',
    },
    EMAIL_TEMPLATES: {
        ALL: 'emailTemplates',
        TENANTS_FILTER: 'tenantsInEmailTemps',
        LOCALES_FILTER: 'localesInEmailTemps',
    },
    APPLICATIONS: {
        ALL: 'applications',
    },
    MODELS: {
        ALL: 'models',
        MODEL_DETAILS: 'modelDetails',
    },
};
