"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const horizon_ui_style_guide_1 = require("@lextegrity/horizon-ui-style-guide");
const Icon = ({ iconName, iconStyle = 'baseline', variant = 'black', size = 'S' }) => {
    const iconUrl = `https://cdn.horizon.lextegrity.com/assets/icons/material/${iconName}/${iconStyle}.svg`;
    let fillColor;
    switch (variant) {
        case 'primary':
            fillColor = 'var(--color-primary)';
            break;
        case 'secondary':
            fillColor = 'var(--color-secondary)';
            break;
        case 'tertiary':
            fillColor = 'var(--color-tertiary)';
            break;
        case 'accent':
            fillColor = 'var(--color-accent)';
            break;
        case 'white':
            fillColor = 'var(--white)';
            break;
        case 'danger':
            fillColor = 'var(--color-danger-dark)';
            break;
        default:
            fillColor = 'var(--black)';
    }
    let iconSize;
    switch (size) {
        case 'XXS':
            iconSize = '0.5rem';
            break;
        case 'XS':
            iconSize = '0.875rem';
            break;
        case 'S':
            iconSize = '1rem';
            break;
        case 'M':
            iconSize = '1.5rem';
            break;
        case 'L':
            iconSize = '2rem';
            break;
        default:
            iconSize = '1rem';
    }
    return ((0, jsx_runtime_1.jsx)(horizon_ui_style_guide_1.Image, { src: iconUrl, fill: fillColor, width: iconSize, height: iconSize, className: `icon-${iconName}` }));
};
exports.default = Icon;
