"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const antd_1 = require("antd");
const react_router_dom_1 = require("react-router-dom");
const react_query_1 = require("@tanstack/react-query");
const ApplicationContext_1 = require("../../../context/ApplicationContext");
const NotFound = ({ title = '404', subTitle = 'Sorry, the page you visited does not exist.', backBtnPath, backBtnText = 'Back Home', }) => {
    const qc = (0, react_query_1.useQueryClient)();
    const { setCurrentTab } = (0, ApplicationContext_1.useApplicationContext)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const { state: { comingFrom } } = (0, react_router_dom_1.useLocation)();
    const redirectTo = comingFrom === 'manage' ? backBtnPath : '/';
    const handleBackClick = () => {
        setCurrentTab('');
        qc.invalidateQueries([backBtnPath.slice(1)]);
        navigate(redirectTo);
    };
    return ((0, jsx_runtime_1.jsx)(antd_1.Result, { status: '404', title: title, subTitle: subTitle, extra: (0, jsx_runtime_1.jsx)(antd_1.Button, Object.assign({ type: 'primary', onClick: handleBackClick }, { children: backBtnText })) }));
};
exports.default = NotFound;
