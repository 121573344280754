"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config = {
    local: {
        zenithConfigApi: 'http://localhost:5000/api',
    },
    dev: {
        zenithConfigApi: 'https://config-service.dev.zenith.lextegrity.com/api',
    },
    qa: {
        zenithConfigApi: 'https://config-service.qa.zenith.lextegrity.com/api',
    },
    stage: {
        zenithConfigApi: 'https://config-service.stage.zenith.lextegrity.com/api',
    },
    prod: {
        zenithConfigApi: 'https://config-service.zenith.lextegrity.com/api',
    },
};
exports.default = () => {
    var _a;
    let configServiceApiEndpoint = {
        zenithConfigApi: '',
    };
    if (process.env.ZENITH_CONFIG_SERVICE_FQDN !== 'localhost:3000') {
        configServiceApiEndpoint = {
            zenithConfigApi: `https://${process.env.ZENITH_CONFIG_SERVICE_FQDN}/api`,
        };
    }
    else {
        const zenithEnv = (_a = process.env.ZENITH_ENV) !== null && _a !== void 0 ? _a : 'local';
        configServiceApiEndpoint = config[zenithEnv];
    }
    return configServiceApiEndpoint;
};
