"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const antd_1 = require("antd");
const react_query_1 = require("@tanstack/react-query");
const ApplicationContext_1 = require("../../context/ApplicationContext");
const { Text } = antd_1.Typography;
const { Item, useForm, useWatch } = antd_1.Form;
const { Password } = antd_1.Input;
const Login = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const { signIn, errorData, isLoggedIn } = (0, ApplicationContext_1.useApplicationContext)();
    const [loginForm] = useForm();
    const formUserNameValue = useWatch('userName', loginForm);
    const formPasswordValue = useWatch('password', loginForm);
    const [searchParams] = (0, react_router_dom_1.useSearchParams)();
    (0, react_1.useEffect)(() => {
        if (!isLoggedIn && errorData)
            antd_1.message.error('Login failed. Please check your credentials.');
        if (isLoggedIn) {
            const redirectTo = searchParams.get('redirectTo');
            if (redirectTo)
                navigate(redirectTo);
            else
                navigate('/configurations');
        }
    }, [isLoggedIn, errorData]);
    const { isLoading, mutate } = (0, react_query_1.useMutation)(({ userName, password }) => __awaiter(void 0, void 0, void 0, function* () { return signIn(userName, password); }));
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () { return mutate(values); });
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'center', style: { height: '100vh' } }, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ style: { width: '30rem', backgroundColor: 'var(--gray-95)', padding: '2rem 4rem' } }, { children: [(0, jsx_runtime_1.jsxs)("div", Object.assign({ className: 'logo-container' }, { children: [(0, jsx_runtime_1.jsx)(antd_1.Image, { src: 'https://cdn.horizon.lextegrity.com/assets/logos/lextegrity-icon-64px.png', preview: false, width: 30 }), (0, jsx_runtime_1.jsx)(Text, Object.assign({ style: { color: 'var(--color-primary-dark)' } }, { children: "Lextegrity" }))] })), (0, jsx_runtime_1.jsxs)(antd_1.Form, Object.assign({ layout: 'vertical', style: { marginTop: '1rem' }, form: loginForm, name: 'login-form', onFinish: handleSubmit }, { children: [(0, jsx_runtime_1.jsx)(Item, Object.assign({ label: 'User Name', name: 'userName' }, { children: (0, jsx_runtime_1.jsx)(antd_1.Input, { "data-testid": 'userName', placeholder: 'Enter User Name' }) })), (0, jsx_runtime_1.jsx)(Item, Object.assign({ label: 'Password', name: 'password', id: 'current-password' }, { children: (0, jsx_runtime_1.jsx)(Password, { "data-testid": 'password', placeholder: 'Enter Password', autoComplete: 'on' }) })), (0, jsx_runtime_1.jsx)(antd_1.Button, Object.assign({ "data-testid": 'login-btn', type: 'primary', htmlType: 'submit', disabled: !formUserNameValue || !formPasswordValue, loading: isLoading }, { children: isLoading ? 'Signing In' : 'Sign In' }))] }))] })) })));
};
exports.default = Login;
